<template>
  <section id="doc">
    <projects />
  </section>
</template>

<script>
import Projects from "@/components/projects";
export default {
  name: "Doc",
  components: {Projects}
}
</script>

<style scoped>

</style>